import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { SystemCode } from '../models/system-codes.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SystemCodeService extends BaseService {

  constructor(
    public httpClient: HttpClient,
    public errorService: ErrorService,
    public authService: AuthService
  ) {
    super('api/systemcode/', httpClient, errorService, authService);
  }

  getAllStates(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForStates', null);
  }

  getAllShifts(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForShifts', null);
  }

  getAllfileTypes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForfileTypes', null);
  }

  getAllStations(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForStations', null);
  }

  getAllInstructors(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForInstructors', null);
  }

  getAllCourseCode(isUpdate: string): Observable<Array<SystemCode>> {
    //return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForCourseCode', null);
    let params = new HttpParams().set('isUpdate', isUpdate);
    return this.customGetRequestWithQueryString<Array<SystemCode>>('GetSystemCodesForCourseCode', params);
  }

  getAllEventType(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForEventType', null);
  }

  getAllRepeatsPeriod(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForRepeatsPeriod', null);
  }

  getSystemCodesForRanks(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForRanks', null);
  }

  getSystemCodesForShifts(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForShifts', null);
  }

  getSystemCodesForPayGrades(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForPayGrades', null);
  }

  getSystemCodesForAwards(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForAwards', null);
  }

  getSystemCodesForDistricts(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForDistricts', null);
  }

  getSystemCodesForKonxBoxs(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForKonxBoxs', null);
  }

  getSystemCodesForContactTypes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForContactTypes', null);
  }

  getSystemCodesForDepartmentUpdate(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForDepartmentUpdate', null);
  }

  //getSystemCodesForKnoxbox(): Observable<Array<SystemCode>> {
  //    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForKnoxbox', null);
  //  }

  getSystemCodesForOccupancyType(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForOccupancyType', null);
  }

  getSystemCodesForInspectionType(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForInspectionType', null);
  }

  getSystemCodesForInspectionConductedBy(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForInspectionConductedType', null);
  }

  getSystemCodesForInspectionResult(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForInspectionResult', null);
  }

  getSystemCodesForRepeats(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForRepeatsPeriod', null);
  }

  getSystemCodesForInstructor(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForInstructor', null);
  }

  getSystemCodesForEmscategory(isUpdate: string): Observable<Array<SystemCode>> {
    //return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForEmscategory', null);
    let params = new HttpParams().set('isUpdate', isUpdate);
    return this.customGetRequestWithQueryString<Array<SystemCode>>('GetSystemCodesForEmscategory', params);
  }

  getSystemCodesForStation(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForStation', null);
  }

  getSystemCodesForIncidentType(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForIncidentType', null);
  }

  getSystemCodesForStatus(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForStatus', null);
  }

  getSystemCodesForNominees(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForNominees', null);
  }

  getSystemCodesForWitnesses(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForWitnesses', null);
  }

  getSystemCodesForCrews(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForCrews', null);
  }

  getSystemCodesForMaintanenceTypes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForMaintanenceTypes', null);
  }

  getSystemCodesForActionRequired(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForActionRequired', null);
  }

  getSystemCodesForPendingType(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForPendingType', null);
  }

  getSystemCodesForBusinessName(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForBusinessName', null);
  }

  getAllPersonnel(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllPersonnel', null);
  }

  getAllPositions(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllPositions', null);
  }

  getAllAssignment(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllAssignment', null);
  }

  getAllUnit(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllUnit', null);
  }

  getAllActivityCode(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllActivityCode', null);
  }

  getAllReplaceOrRepairs(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllReplaceOrRepair', null);
  }

  getAllGearTypes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllGearType', null);
  }

  getAllRunValues(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForRunValues', null);
  }

  getSystemCodesForMoistureBarrier(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetAllMoistureBarrier', null);
  }

  getSystemCodesForCertificationTypes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetCertificationTypes', null);
  }

  getSystemCodesForTrainingTypes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetTrainingTypes', null);
  }

  //getSystemCodesForSubTypes(): Observable<Array<SystemCode>> {
  //  return this.customGetRequest<Array<SystemCode>>('getSystemCodesForSubTypes', null);
  //}

  getSystemCodesForApparatus(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForApparatus', null);
  }
  getSystemCodesByCode(code: string): Observable<Array<SystemCode>> {
    console.log(code)
    const params = new HttpParams()
      .set('code', code);

    return this.customGetRequestWithQueryString<Array<SystemCode>>('getSystemCodesByCode', params);
  }

  getSystemCodesForEquipment(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForEquipment', null);
  }

  getSystemCodesForStationItems(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForStationItems', null);
  }

  getSystemCodesForCompanyTrainingCrews(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForCompanyTrainingCrews', null);
  }

  getSystemCodesForCompanyTrainingStations(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForCompanyTrainingStations', null);
  }

  getSystemCodesForBuisness(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForBuisness', null);
  }

  getSystemCodesForStreetNames(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForStreetNames', null);
  }

  getSystemCodesForYesNo(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForYesNo', null);
  }

  getSystemCodesForEMSTrainingInstructor(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForEMSTrainingInstructor', null);
  }

  getAllEMSTrainingCodes(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllEMSTrainingCodes', null);
  }

  getSystemCodesForYearlyMonthly(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForYearlyMonthly', null);
  }

  getSystemCodesForIYearlyMonthlyDays(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForIYearlyMonthlyDays', null);
  }

  getSystemCodesForOccupancySubTypeCommercial(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForOccupancySubTypeCommercial', null);
  }

  getSystemCodesForOccupancySubTypeResidential(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForOccupancySubTypeResidential', null);
  }

  getSystemCodesForOccupancySubTypeChildBusiness(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForOccupancySubTypeChildBusiness', null);
  }

  getSystemCodesForOccupancySubTypeChildAssembly(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForOccupancySubTypeChildAssembly', null);
  }

  getSystemCodesForEMSCompanyTrainingCrews(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForEMSCompanyTrainingCrews', null);
  }

  getSystemCodesForEMSCompanyTrainingStations(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForEMSCompanyTrainingStations', null);
  }

  getSystemCodesForEMSTrainingInstructorForDisplay(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllEMSTrainingCodesInstructorForDisplay', null);
  }

  getSystemCodesForEMSTrainingCodeForDisplay(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getAllEMSTrainingCodesForDisplay', null);
  }

  getSystemCodesForEventDuration(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForEventDuration', null);
  }

  getSystemCodesForEventMonths(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('getSystemCodesForEventMonths', null);
  }

  getSystemCodesForAward(): Observable<Array<SystemCode>> {
    return this.customGetRequest<Array<SystemCode>>('GetSystemCodesForAward', null);
  }

  getDefaultActivity(): Observable<any> {
    return this.customGetRequest<Array<SystemCode>>('getDefaultActivity', null);
  }
  //getFileSize(): Observable<any> {
  //  return this.customGetRequest<Array<SystemCode>>('getFileSizeData', null);
  //}
  //getFileSizeText(): Observable<any> {
  //  return this.customGetRequest<Array<SystemCode>>('getFileSizeTextData', null);
  //}
  getFileSize(action: string): Observable<SystemCode> {
    return this.customGetRequest<SystemCode>(action, null);
  }
  getFileSizeText(action: string): Observable<SystemCode> {
    return this.customGetRequest<SystemCode>(action, null);
  }
  getFileSizeValueText(action: string): Observable<SystemCode> {
    return this.customGetRequest<SystemCode>(action, null);
  }
  //getMaintanenceRequestUserName(action: string): Observable<string> {
  //  return this.customGetRequest<string>(action, null);
  //}
}
